import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'
import MapGL, { Marker } from 'react-map-gl'

import MapPin from '~components/Shared/MapPin'
import Layout from '~components/Layout'
import scssVars from '~components/scss-variables'
import SEO from '~components/seo'
import { MapContext } from '~providers/MapContext'

const Heading = styled.h1`
  font-size: 36px;
  font-weight: 300;
  margin-bottom: 0.5em;
`

const ScrollContainer = styled.div`
  max-height: 80vh;
  height: 1500px;
  overflow-y: scroll;
`

const StoreHeading = styled.h3`
  position: relative;
  font-size: 16px;
  font-weight: 600;
  a, a:hover, a:focus {
    color: ${scssVars.darkBrown};
  }
  &:before {
    position: absolute;
    content: '';
    background: url(${props => props.marker}) no-repeat center / contain;
    display: block;
    height: 25px;
    width: 15px;
    left: -25px;
  }
`

const DetailsLink = styled(Link)`
  text-decoration: underline;
  font-style: italic;
`

const StoreList = styled.ul`
  padding-left: 0;
  list-style: none;
  p {
    line-height: 1.25;
    margin-bottom: 0;
  }
  li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 20px;
  }
`

const ModalWrapper = styled.div`
  display: ${props => props.open ? 'block' : 'none'};
  width: 350px;
  height: 75vh;
  background-color: white;
  color: ${scssVars.darkBrown};
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 3;
  overflow-y: scroll;
  h4 {
    font-size: 16px;
  }
`

const CloseButton = styled.a`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  svg {
    width: 22px;
    line {
      transition: stroke 0.25s;
      stroke: ${scssVars.beige};
      stroke-linecap: round;
      stroke-width: 2px;
    }
  }
  &:hover {
    svg line {
      stroke: ${scssVars.brown};
    }
  }
`

const Content = styled.div`
  h2 {
    font-size: 14px;
    font-weight: 600;
  }
`

const StoreModal = ({ store, modalOpen, closeModal }) => {
  return (
    <ModalWrapper open={modalOpen}>
      <div className="mt-4">
        <StoreHeading marker={store.pins[1]} className="mb-0 ml-5">
          {store.title}
        </StoreHeading>
        <CloseButton type="button" onClick={() => closeModal(!modalOpen)}>
          <svg xmlns="http://www.w3.org/2000/svg">
            <g>
              <line x2="20" y2="20" transform="translate(1.414 1.414)"/>
              <line x2="20" y2="20" transform="translate(21.414 1.414) rotate(90)"/>
            </g>
          </svg>
        </CloseButton>
      </div>
      <img src={store.images[0].src} className="w-100 mt-3" alt={'store image'} style={{ height: 210 }} />
      { store && <Content dangerouslySetInnerHTML={{ __html: store.html }} className="p-3" /> }
    </ModalWrapper>
  )
}

StoreModal.propTypes = {
  store: PropTypes.object,
  modalOpen: PropTypes.bool,
  closeModal: PropTypes.func
}

export default function LocationsPage () {
  const [selectedStore, setSelectedStore] = useState(0)
  const [storeObj, setStoreObj] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

  const viewport = {
    width: '100%',
    height: '100%',
    latitude: 40.7339689,
    longitude: -73.9884421,
    zoom: 13
  }

  const toggleStore = (index, store) => {
    setSelectedStore(index)
    setStoreObj(store)
    setModalOpen(true)
  }

  const { allMarkdownRemark: { edges } } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "location-page"}}}, sort: {fields: frontmatter___order, order: ASC}) {
          edges {
            node {
              frontmatter {
                title
                path
                address
                latitude
                longitude
                pins
                images {
                  src
                }
              }
              html
            }
          }
        }
      }
    `
  )
  const stores = edges.map(edge => {
    return { ...edge.node.fields, ...edge.node.frontmatter, html: edge.node.html }
  })
  const Map = () => (
    <MapGL
      {...viewport}
      scrollZoom={false}
      doubleClickZoom={false}
      mapStyle={process.env.MAPBOX_STYLE}
      mapboxApiAccessToken={process.env.MAPBOX_TOKEN}
    >
      {stores.map((s, i) => (
        <Marker key={`marker-${i}`} latitude={s.latitude} longitude={s.longitude}>
          <MapPin size={20} pins={s.pins} index={i} />
        </Marker>
      ))}
    </MapGL>
  )

  return (
    <Layout>
      <SEO title="Locations" pathname="/locations" description="Leather Spa's Manhattan Locations" />
      <main>
        <Container fluid>
          <Row>
            <Col lg={{ size: 4 }} xl={{ size: 3 }} className="pr-0">
              <ScrollContainer className="py-5 pl-5 pr-3">
                <Heading>Stores</Heading>
                <StoreList>
                  {stores.map((store, i) => (
                    <li key={i}>
                      <StoreHeading marker={selectedStore === i ? store.pins[1] : store.pins[0]}>
                        <a onClick={() => toggleStore(i, store)}>{store.title}</a>
                      </StoreHeading>
                      <p>{store.address}</p>
                      <DetailsLink to={store.path}>Details</DetailsLink>
                    </li>
                  ))}
                </StoreList>
              </ScrollContainer>
            </Col>
            <Col className="px-0">
              {storeObj && <StoreModal store={storeObj} modalOpen={modalOpen} closeModal={setModalOpen} />}
              <MapContext.Provider value={selectedStore}>
                <Map />
              </MapContext.Provider>
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}
